import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material'
import { Facebook, Instagram } from '@mui/icons-material'
import React, { useState } from 'react'

function UserProfile(props) {
  console.log('UserProfile component rendered with props:', props)
  const { response, handleLogout, handleDeleteData } = props
  const [isReportDialogOpen, setReportDialogOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [problemDescription, setProblemDescription] = useState('')

  // console.log('Initial state of the report dialog and form fields:', {
  //   isReportDialogOpen,
  //   email,
  //   problemDescription,
  // })

  // const handleOpenReportDialog = () => {
  //   console.log('Opening report dialog...')
  //   setReportDialogOpen(true)
  // }

  // const handleCloseReportDialog = () => {
  //   console.log('Closing report dialog...')
  //   setReportDialogOpen(false)
  // }

  // const handleEmailChange = (event) => {
  //   console.log(`Updating email field: ${event.target.value}`)
  //   setEmail(event.target.value)
  // }

  // const handleProblemDescriptionChange = (event) => {
  //   console.log(`Updating problem description: ${event.target.value}`)
  //   setProblemDescription(event.target.value)
  // }

  // const handleReportSubmit = () => {
  //   console.log('Submitting report with data:', { email, problemDescription })
  //   // Add your logic for handling the report submission here
  //   // You can send the email and problemDescription to your server, for example
  //   // Reset the form fields and close the dialog
  //   setEmail('')
  //   setProblemDescription('')
  //   setReportDialogOpen(false)
  // }

  console.log('response', response)

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item lg={5} md={6} xs={12}>
        <Card elevation={3}>
          <CardHeader
            title="User Information"
            titleTypographyProps={{ variant: 'h5' }}
            avatar={
              <Avatar
                src={response[0].profile_picture_url}
                alt={response[0].name}
                variant="circle"
              />
            }
          />
          <CardContent>
            <Typography variant="subtitle1" gutterBottom>
              Hey {response[0].name}, You are now logged in!
            </Typography>
            <Typography variant="h6" gutterBottom>
              Connected Pages:
            </Typography>
            <List>
              {response[0].pages &&
                response[0].pages.map((page, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar src={page.profile_picture_url} alt={page.name}>
                        {/* Fallback to Facebook icon if no image URL is provided */}
                        {!page.profile_picture_url && <Facebook />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={page.name} />
                  </ListItem>
                ))}
            </List>

            <p className="user-profile-text">
              Thank you for authorising Threat Matrix Direct Messaging Services.
            </p>
            <p className="user-profile-text">
              You will be contacted periodically to remind you that the service
              is running.
            </p>
            <p className="user-profile-text">
              You may opt out of the service at any time by emailing
              threatmatrix@signify.ai
            </p>

            {response[0].instagramid && (
              <>
                <Typography variant="h6" gutterBottom>
                  Connected Instagram Account:
                </Typography>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Instagram />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={response[0].instagramdetails}
                      secondary={JSON.parse(response[0].instagramid)}
                    />
                  </ListItem>
                </List>
              </>
            )}
          </CardContent>
          {/* <CardActions>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleLogout}
                  startIcon={<Facebook />}
                  fullWidth
                >
                  Logout
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDeleteData}
                  fullWidth
                >
                  Opt Out & Delete Data
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenReportDialog}
                  fullWidth
                >
                  Report
                </Button>
              </Grid>
            </Grid>
          </CardActions> */}
        </Card>
        {/* <Dialog
          open={isReportDialogOpen}
          onClose={handleCloseReportDialog}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Report a Problem</DialogTitle>
          <DialogContent>
            <TextField
              label="Email Address"
              fullWidth
              margin="normal"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
            />
            <TextField
              label="Problem Description"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              variant="outlined"
              value={problemDescription}
              onChange={handleProblemDescriptionChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseReportDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleReportSubmit}
              color="primary"
              variant="contained"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog> */}
      </Grid>
    </Grid>
  )
}

export default UserProfile
