// @ts-nocheck
import UserProfile from './Components/UserDashboard'
import { FacebookLoginClient } from '@greatsumini/react-facebook-login'
import Login from './Components/Login'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'

const App = () => {
  const [fbResponse, setFbResponse] = useState([])
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    console.log('Checking loading state...')
    setIsLoading(false)
  }, [])

  useEffect(() => {
    console.log('Checking login state...')

    // Assuming fbResponse is an array of user objects or an empty array
    if (fbResponse.length > 0 && fbResponse[0]?.fb_user_id) {
      // Check if there's a user and the user has a Facebook User ID
      console.log(`User with ID ${fbResponse[0].fb_user_id} is logged in.`)
      setIsLoggedIn(true)
    } else {
      // No valid user data found, consider the user not logged in
      console.log('No logged-in user found.')
      setIsLoggedIn(false)
    }
  }, [fbResponse]) // Depend on fbResponse to re-run this effect

  let backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001'

  const handleLogout = async () => {
    console.log('Initiating logout...')
    FacebookLoginClient.logout(async () => {
      try {
        // Call to backend to destroy server session
        const response = await axios.post(`${backendUrl}/logout`)
        console.log(response.data) // Logged out successfully

        // Clear local data and state
        setFbResponse([])
        localStorage.clear()
        setIsLoggedIn(false)
        console.log(
          'Logged out: fbResponse cleared, localStorage cleared, and user set to logged out state.',
        )
      } catch (error) {
        console.error('Error logging out:', error)
      }
    })
  }

  const handleDeleteData = async () => {
    console.log('Initiating data deletion for user:', fbResponse[0].userid)
    try {
      await axios.delete(`${backendUrl}/users`, {
        data: { userid: fbResponse[0].userid },
      })
      console.log('Data deletion successful for user:', fbResponse[0].userid)
      setFbResponse([])
      localStorage.clear()
      setIsLoggedIn(false)
    } catch (error) {
      console.error('Data deletion failed:', error)
    }
  }

  const setFbDetails = (data) => {
    console.log('Setting FB details with data:', data)

    // If data is not an array, wrap it into an array for consistency
    const normalizedData = Array.isArray(data) ? data : [data]

    // Update the state with the normalized data
    setFbResponse(normalizedData)

    // Optionally, you might want to update isLoggedIn here as well
    if (normalizedData.length > 0 && normalizedData[0].fb_user_id) {
      console.log(
        `User with ID ${normalizedData[0].fb_user_id} isUser now considered logged in.`,
      )
      setIsLoggedIn(true)
    } else {
      console.log('No valid user data received, setting user as logged out.')
      setIsLoggedIn(false)
    }
  }

  return (
    <BrowserRouter>
      {' '}
      {/* Wrap the return statement in BrowserRouter */}
      <Routes>
        <Route
          path="/tennis"
          element={
            isLoading ? (
              <div>Loading...</div>
            ) : isLoggedIn ? (
              <UserProfile
                response={fbResponse}
                handleLogout={handleLogout}
                handleDeleteData={handleDeleteData}
              />
            ) : (
              <Login
                setFbResponse={setFbResponse}
                setFbDetails={setFbDetails}
                variant="tennis"
              />
            )
          }
        />
        <Route
          path="/rugby"
          element={
            isLoading ? (
              <div>Loading...</div>
            ) : isLoggedIn ? (
              <UserProfile
                response={fbResponse}
                handleLogout={handleLogout}
                handleDeleteData={handleDeleteData}
              />
            ) : (
              <Login
                setFbResponse={setFbResponse}
                setFbDetails={setFbDetails}
                variant="rugby"
              />
            )
          }
        />
        <Route
          path="/worldrugby"
          element={
            isLoading ? (
              <div>Loading...</div>
            ) : isLoggedIn ? (
              <UserProfile
                response={fbResponse}
                handleLogout={handleLogout}
                handleDeleteData={handleDeleteData}
              />
            ) : (
              <Login
                setFbResponse={setFbResponse}
                setFbDetails={setFbDetails}
                variant="worldrugby"
              />
            )
          }
        />
        <Route
          path="/"
          element={
            isLoading ? (
              <div>Loading...</div>
            ) : isLoggedIn ? (
              <UserProfile
                response={fbResponse}
                handleLogout={handleLogout}
                handleDeleteData={handleDeleteData}
              />
            ) : (
              <Login
                setFbResponse={setFbResponse}
                setFbDetails={setFbDetails}
              />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
