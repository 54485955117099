import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Facebook, Instagram } from '@mui/icons-material'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import axios from 'axios'

// import signifyLogo from './images/signify-logo.png'
import signifyLogoTransp from './images/signify_logo_transparent_black.png'
import threatMatrixLogoTransp from './images/Threat_Matrix_Logo.png'
import wta from './images/wta-l.png'
import itf from './images/itf-l.png'
import usta from './images/usta-l.png'
import wimbledon from './images/wimbledon-l.png'
import epcr from './images/epcr-l.png'
import worldrugby from './images/worldrugby.png'


const Login = ({ setFbResponse, setFbDetails }) => {
  let backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000'

  // console.log('REACT_APP_BACKEND_URL:', process.env.REACT_APP_BACKEND_URL)
  const location = useLocation()

  useEffect(() => {
    console.log('Checking local storage for existing user data...')
    let now = new Date().getTime()
    let setupTime = localStorage.getItem('setupTime')
    if (setupTime) {
      console.log(`Found setup time: ${setupTime}`)
      let hours = 1 // Or however long you want to keep the user logged in
      if (now - setupTime > hours * 60 * 60 * 1000) {
        console.log('Local storage data expired, clearing data...')
        localStorage.clear()
        localStorage.setItem('setupTime', now)
      } else {
        console.log('User data is still valid, setting user data to state...')
        let localUserData = localStorage.getItem('userData')
        if (localUserData) {
          let savedUser = JSON.parse(localUserData)
          setFbDetails([savedUser])
        }
      }
    } else {
      console.log('No setup time found, initializing setup time...')
      localStorage.setItem('setupTime', now)
    }
  }, [])

  const responseFacebook = async (response) => {
    console.log('Facebook login response received:', response)

    if (response.status === 'unknown') {
      console.error('Facebook login response status unknown. Reloading...')
      window.location.reload(false)
      return // Early return to prevent further execution
    }

    console.log('project: ', location.pathname.substring(1))

    try {
      console.log('Sending data to /login endpoint...')
      let loginResponse = await axios.post(`${backendUrl}/login`, {
        accessToken: response.accessToken,
        userID: response.userID,
        name: response.name,
        email: response.email, // assuming email is part of the response
        profilePictureUrl: response.picture.data.url,
        project: location.pathname.substring(1) || 'none' // strip the leading '/' and send as the project
      })


      console.log('Login response:', loginResponse.data)

      // Setting user details in local state and local storage
      setFbDetails(loginResponse.data.user) // adjust depending on actual response structure
      localStorage.setItem('userData', JSON.stringify(loginResponse.data.user))
      console.log('User data set in local state and storage.')
    } catch (error) {
      console.error('Error during Facebook login:', error)
    }
  }

  const responseInstagram = async (response) => {
    console.log('Instagram login response received:', response)

    if (response.status === 'unknown') {
      console.error('Instagram login response status unknown. Reloading...')
      window.location.reload(false)
      return // Early return to prevent further execution
    }

    try {
      console.log('Exchanging short-lived token for long-lived token...')
      let tokenExchangeRes = await axios.post(`${backendUrl}/exchange-token`, {
        userId: response.userID, // Make sure to send necessary data
        shortLivedToken: response.accessToken,
      })
      console.log('Token exchanged:', tokenExchangeRes.data)

      let longLivedToken = tokenExchangeRes.data.longLivedToken
      let tokenExpiry = tokenExchangeRes.data.tokenExpiry

      console.log('Fetching user pages...')
      let pageTokenRes = await axios.get(
        `https://graph.facebook.com/v16.0/${response.userID}/accounts?access_token=${response.accessToken}`,
      )
      console.log('User pages:', pageTokenRes.data)

      console.log('Saving user with exchanged token and received user data...')
      let savedUser = await axios.post(`${backendUrl}/users`, {
        name: response.name,
        userId: response.userID,
        accessToken: response.accessToken,
        longLivedToken: longLivedToken,
        tokenExpiry: tokenExpiry,
        profilepictureurl: response.picture.data.url,
        pages: pageTokenRes.data.data,
      })
      console.log('User saved:', savedUser.data)

      // Setting user details in local state and local storage
      setFbDetails(savedUser.data)
      localStorage.setItem('userData', JSON.stringify(savedUser.data))
      console.log('User data set in local state and storage.')
    } catch (error) {
      console.error('Error in Instagram login process:', error)
    }
  }

  // Conditionally render based on the path
  if (location.pathname === '/tennis') {
    // Return the new version of the login layout for /tennis
    return (
      <>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12} md={8} lg={5}>
            <div className="text-box">
              <div className="login-container">
                <h1>Tennis Social Media Abuse Support Service</h1>
                <div className="logo-container">
                  <div class="logo-pair">
                    <img src={wta} alt="WTA" />
                    <img src={itf} alt="ITF" />
                  </div>
                  <div class="logo-pair">
                    <img src={usta} alt="USTA" />
                    {/* <img src={usta} alt="USTA" className="larger-logo" /> */}
                    <img src={wimbledon} alt="Wimbledon" />
                  </div>
                </div>
              </div>

              <h1 className="login-heading">
                Sign-Up for Direct Messaging support, provided by:
              </h1>
              <div className="centered-logo">
                <img
                  src={threatMatrixLogoTransp}
                  alt="Threat Matrix Logo"
                  className="smaller-logo"
                />
              </div>

              <p className="login-subheading bold-text">
                Welcome to the sign-up for automated monitoring of your Direct
                Messages on Meta (Facebook and Instagram).
              </p>

              <p className="login-text">
                By signing up, your new Direct Message requests will be
                monitored for abuse and threats, and will be reported and
                investigated on your behalf. Note - messages to/from established
                contacts will not be monitored by this service.
              </p>
              <p className="login-text">
                To authorize this service, please log in using the sign-in with
                Facebook button below and follow the 'Edit previous settings'
                prompt.
              </p>
              <p className="login-text">
                During sign-in, you will be able to select the pages and
                accounts to which you want to grant access to the service.
              </p>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="facebook-button-container">
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                scope="business_management,pages_show_list,pages_messaging,pages_manage_metadata,pages_read_engagement,instagram_basic,instagram_manage_messages"
                fields="name,email,picture"
                callback={responseFacebook}
                render={(renderProps) => (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#1877F2',
                      color: 'white',
                      padding: '10px 20px',
                      fontSize: '14px',
                    }}
                    startIcon={<Facebook />}
                    onClick={renderProps.onClick}
                  >
                    Sign in with Facebook
                  </Button>
                )}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="login-container">
              <p className="login-smalltext">Powered by:</p>
              <img src={signifyLogoTransp} alt="Logo" className="login-logo" />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }

  // Conditionally render based on the path
  if (location.pathname === '/rugby') {
    // Return the new version of the login layout for /rugby
    return (
      <>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12} md={8} lg={5}>
            <div className="text-box">
              <div className="login-container">
                <h1>Rugby Social Media Abuse Support Service</h1>
                <div className="logo-container">
                  <img src={epcr} alt="EPCR" />
                </div>
              </div>

              <h1 className="login-heading">
                Sign-Up for Direct Messaging support, provided by:
              </h1>
              <div className="centered-logo">
                <img
                  src={threatMatrixLogoTransp}
                  alt="Threat Matrix Logo"
                  className="smaller-logo"
                />
              </div>

              <p className="login-subheading bold-text">
                Welcome to the sign-up for automated monitoring of your Direct
                Messages on Meta (Facebook and Instagram).
              </p>

              <p className="login-text">
                By signing up, your new Direct Message requests will be
                monitored for abuse and threats, and will be reported and
                investigated on your behalf. Note - messages to/from established
                contacts will not be monitored by this service.
              </p>
              <p className="login-text">
                To authorize this service, please log in using the sign-in with
                Facebook button below and follow the 'Edit previous settings'
                prompt.
              </p>
              <p className="login-text">
                During sign-in, you will be able to select the pages and
                accounts to which you want to grant access to the service.
              </p>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="facebook-button-container">
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                scope="business_management,pages_show_list,pages_messaging,pages_manage_metadata,pages_read_engagement,instagram_basic,instagram_manage_messages"
                fields="name,email,picture"
                callback={responseFacebook}
                render={(renderProps) => (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#1877F2',
                      color: 'white',
                      padding: '10px 20px',
                      fontSize: '14px',
                    }}
                    startIcon={<Facebook />}
                    onClick={renderProps.onClick}
                  >
                    Sign in with Facebook
                  </Button>
                )}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="login-container">
              <p className="login-smalltext">Powered by:</p>
              <img src={signifyLogoTransp} alt="Logo" className="login-logo" />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }

  // Conditionally render based on the path
  if (location.pathname === '/worldrugby') {
    // Return the new version of the login layout for /worldrugby
    return (
      <>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12} md={8} lg={5}>
            <div className="text-box">
              <div className="login-container">
                <h1>Rugby Social Media Abuse Support Service</h1>
                <div className="logo-container">
                  <img src={worldrugby} alt="worldrugby" />
                </div>
              </div>

              <h1 className="login-heading">
                Sign-Up for Direct Messaging support, provided by:
              </h1>
              <div className="centered-logo">
                <img
                  src={threatMatrixLogoTransp}
                  alt="Threat Matrix Logo"
                  className="smaller-logo"
                />
              </div>

              <p className="login-subheading bold-text">
                Welcome to the sign-up for automated monitoring of your Direct
                Messages on Meta (Facebook and Instagram).
              </p>

              <p className="login-text">
                By signing up, your new Direct Message requests will be
                monitored for abuse and threats, and will be reported and
                investigated on your behalf. Note - messages to/from established
                contacts will not be monitored by this service.
              </p>
              <p className="login-text">
                To authorize this service, please log in using the sign-in with
                Facebook button below and follow the 'Edit previous settings'
                prompt.
              </p>
              <p className="login-text">
                During sign-in, you will be able to select the pages and
                accounts to which you want to grant access to the service.
              </p>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="facebook-button-container">
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                scope="business_management,pages_show_list,pages_messaging,pages_manage_metadata,pages_read_engagement,instagram_basic,instagram_manage_messages"
                fields="name,email,picture"
                callback={responseFacebook}
                render={(renderProps) => (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#1877F2',
                      color: 'white',
                      padding: '10px 20px',
                      fontSize: '14px',
                    }}
                    startIcon={<Facebook />}
                    onClick={renderProps.onClick}
                  >
                    Sign in with Facebook
                  </Button>
                )}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="login-container">
              <p className="login-smalltext">Powered by:</p>
              <img src={signifyLogoTransp} alt="Logo" className="login-logo" />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12} md={8} lg={5}>
          <div className="text-box">
            <div className="login-container">
              <h1>Social Media Abuse Support Service</h1>
            </div>

            <h1 className="login-heading">
              Sign-Up for Direct Messaging support, provided by:
            </h1>
            <div className="centered-logo">
              <img
                src={threatMatrixLogoTransp}
                alt="Threat Matrix Logo"
                className="smaller-logo"
              />
            </div>

            <p className="login-subheading bold-text">
              Welcome to the sign-up for automated monitoring of your Direct
              Messages on Meta (Facebook and Instagram).
            </p>

            <p className="login-text">
              By signing up, your new Direct Message requests will be
              monitored for abuse and threats, and will be reported and
              investigated on your behalf. Note - messages to/from established
              contacts will not be monitored by this service.
            </p>
            <p className="login-text">
              To authorize this service, please log in using the sign-in with
              Facebook button below and follow the 'Edit previous settings'
              prompt.
            </p>
            <p className="login-text">
              During sign-in, you will be able to select the pages and
              accounts to which you want to grant access to the service.
            </p>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className="facebook-button-container">
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              scope="business_management,pages_show_list,pages_messaging,pages_manage_metadata,pages_read_engagement,instagram_basic,instagram_manage_messages"
              fields="name,email,picture"
              callback={responseFacebook}
              render={(renderProps) => (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#1877F2',
                    color: 'white',
                    padding: '10px 20px',
                    fontSize: '14px',
                  }}
                  startIcon={<Facebook />}
                  onClick={renderProps.onClick}
                >
                  Sign in with Facebook
                </Button>
              )}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className="login-container">
            <p className="login-smalltext">Powered by:</p>
            <img src={signifyLogoTransp} alt="Logo" className="login-logo" />
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default Login

// scope="business_management,pages_show_list,pages_messaging,pages_manage_metadata,pages_read_engagement,instagram_basic,instagram_manage_messages"
