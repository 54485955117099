import reportWebVitals from './reportWebVitals'
import ReactDOM from 'react-dom/client'
import React from 'react'
import App from './App'
import './index.css'

if (process.env.LOG_LEVEL !== 'dev') {
  console.log = function () {}
}
console.log('process.env.LOG_LEVEL', process.env.LOG_LEVEL)


// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

reportWebVitals()
